import React, { useEffect, useState } from "react";
import "../assets/app.css";
import axios from "../axios.js";
import GraphemeSplitter from "grapheme-splitter";
import { LoadingSpinner } from "../Components/loadingSpinner";

// import dummy from "./../assets/dummyData/biddersList.json";
import { Link } from "react-router-dom";
import createAndSubmitCybersourceForm from "../utils/createAndSubmitCybersourceForm";
import { isValidPhoneNumber } from "libphonenumber-js";
export const ReserveName = () => {
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [font, setFont] = useState({});
  const [nickNamesList, setNickNamesList] = useState([""]);
  const [paymentOptn, setPaymentOptn] = useState("direct");
  const [selectedNickName, setSelectedNickName] = useState("");
  const [bidAmount, setBidAmount] = useState(0);
  const [bidderList, setBidderList] = useState([]);
  const [bidParameter, setBidParameter] = useState({});
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [currency, setCurrency] = useState("INR");
  const [amountMultiplier, setAmountMultiplier] = useState(1);
  const splitter = new GraphemeSplitter();

  React.useEffect(() => {
    axios.get("/api/v3/names/available-fonts").then((res) => {
      setFont(res.data);
    });
    axios.get("/api/v3/names/amount-multiplier").then((res) => {
      console.log(res.data);
      setAmountMultiplier(res.data.multiplier);
      setCurrency(res.data.currency);
      setCountryCode(res.data.currency === "INR" ? "+91" : "+977");
    });
  }, []);

  useEffect(() => {
    axios
      .get("/api/v3/names/amount-multiplier", {
        params: {
          currency: countryCode === "+977" ? "NPR" : "INR",
        },
      })
      .then((res) => {
        setCurrency(countryCode === "+977" ? "NPR " : "INR ");
        setAmountMultiplier(res.data.multiplier);
      });
  }, [countryCode]);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(`api/v3/names/bid-amount`, {
        params: {
          name: selectedNickName,
          countryCode: countryCode,
        },
      })
      .then((res) => {
        setBidParameter(res.data);
        setBidAmount(res.data.minBidAmount);
      })
      .catch((error) => {
        setMessage("Something went wrong.");
      });
    axios
      .get(`api/v3/names/bid-list`, {
        params: {
          name: selectedNickName,
          countryCode: countryCode,
        },
      })
      .then((res) => {
        setBidderList(res.data.data);
      })
      .catch(() => {
        setMessage("Something went wrong.");
      });
    setLoading(false);
  }, [selectedNickName, countryCode, loading]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMessage("");
    if (name === "countryCode") {
      setCountryCode(value);
      return;
    }
    if (name === "number") {
      if (!isValidPhoneNumber(value, "IN")) {
        // check if valid number
        setMessage("Please enter valid number");
      }
      setNumber(value);
    }
    if (name === "name") {
      setName(value);
      setPaymentOptn("direct");
      setSelectedNickName("");
      let indexes = [];
      for (let i = 0; i < value.length; i++) {
        const a = indexes.push(font.format.indexOf(value.charAt(i)));
      }

      const indexVal = indexes.map((index) => {
        return font.samples
          .map((sample) => {
            return sample[index];
          })
          .join("");
      });

      const concatenatedArray = [];
      for (let i = 0; i < 10; i++) {
        let segment = "";

        for (let j = 0; j < indexVal.length; j++) {
          const val = splitter.splitGraphemes(indexVal[j]);

          // if (i < val.length) {
          //   segment += val[i];
          // } else {
          //   segment += value.charAt(value.length - 1);
          // }
          if (val[i]) {
            segment += val[i];
          } else {
            segment += value.slice(j, j + 1);
          }
        }
        concatenatedArray.push(segment);
      }

      let final = Array.from(new Set(concatenatedArray));
      let customizedFinal = [];
      let customizedStr = [];
      for (let i = 0; i < final.length; i++) {
        for (let j = 0; j < font.customization.length; j++) {
          customizedStr =
            font.customization[j].prepend +
            final[i] +
            font.customization[j].append;
          customizedFinal.push(customizedStr);
        }
      }

      setNickNamesList(final.concat(customizedFinal));
    }

    if (name === "selectedNickName") {
      setSelectedNickName(value.trim());
    }
    if (name === "paymentOptn") {
      if (value === "bid") {
        if (!name) {
          setPaymentOptn("direct");
          setMessage("Enter your name");
          return;
        }
        if (!selectedNickName) {
          setPaymentOptn("direct");
          setMessage("Select your stylish Id.");
          return;
        }
      }
      setPaymentOptn(value);
    }
    if (name === "bidAmount") {
      setBidAmount(value);
    }
  };

  const getAmountWithMultiplier = (amount, multiplier) => {
    return parseFloat(amount) * parseFloat(multiplier);
  };

  //Reserve directly
  const handleReserve = (event) => {
    event.preventDefault();
    if (!number) {
      return setMessage("Enter your Phone Number.");
    }

    if (!name) {
      return setMessage("Enter your name.");
    }

    if (!Object.keys(selectedNickName).length > 0) {
      return setMessage("Select your stylish Id");
    }

    //TODO PAYMENT
    setLoading(true);
    axios
      .post("api/v3/names/direct-pay", {
        amount: bidParameter.directPayAmount,
        name: selectedNickName,
        phoneNumber: number,
        countryCode: countryCode,
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          createAndSubmitCybersourceForm(res.data.data);
          return setMessage("Redirecting to payment.");
        } else {
          return setMessage("Something went wrong");
        }
      })
      .catch(() => {
        setMessage("Something went wrong");
      });
  };

  //Bid
  const handleBid = () => {
    //DO SOMETHING
    if (!(bidAmount >= bidParameter.minBidAmount)) {
      return setMessage("Invalid amount.");
    }
    if (!number) {
      return setMessage("Enter your phone number.");
    }
    setLoading(true);
    axios
      .post("api/v3/names/bid-name", {
        name: selectedNickName,
        phoneNumber: number,
        amount: bidAmount,
        countryCode: countryCode,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          createAndSubmitCybersourceForm(res.data.data);
        }
      })
      .catch(() => {
        setMessage("Something went wrong.");
      });
  };

  return (
    <div className="main-wrapper">
      <div className="hero-banner">
        <div className="hero-banner-content container d-flex align-items-center justify-content-center">
          <div className="w-100">
            <div className="form-modal form-box w-100">
              <div className="modal-header">
                <div className="row">
                  <div className="col-sm-12 col-lg-6">
                    <h5
                      className="font-weight-bold text-align-left"
                      style={{ color: "#FF900A" }}
                    >
                      Reserve your Nimbuzz Stylish ID.
                    </h5>
                  </div>
                  <div className="col-sm-12 col-lg-6 d-flex justify-content-lg-end">
                    <Link to="/top-bids-name">View top bids</Link>
                  </div>
                </div>
              </div>
              <div className="modal-body justify-content-left align-items-left text-align-left">
                {message.length > 0 && (
                  <div className="show-message">{message}</div>
                )}
                {loading && <LoadingSpinner />}
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <form>
                      <div className="form-group">
                        {/* {number && (
                          <label className="font-weight-light">
                            Phone Number
                          </label>
                        )} */}
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select
                              name="countryCode"
                              className="custom-select w-100"
                              onChange={handleChange}
                              value={countryCode}
                            >
                              <option value={"+91"}>+91</option>
                              <option value={"+977"}>+977</option>
                            </select>
                          </div>
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="Enter Your Phone Number"
                            required
                            name="number"
                            value={number}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        {/* {name && (
                          <label className="font-weight-light">Name</label>
                        )} */}

                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Your Name"
                          required
                          name="name"
                          value={name}
                          onChange={handleChange}
                        />
                      </div>
                      {
                        <div className="my-3">
                          {/* {selectedNickName && (
                            <label className="font-weight-light">
                              Select your stylish Id
                            </label>
                          )} */}
                          <select
                            className="custom-select w-100"
                            name="selectedNickName"
                            onChange={handleChange}
                            key={name}
                            required
                            disabled={name.length === 0}
                          >
                            <option className="font-weight-light" value="">
                              Select your stylish Id
                            </option>
                            {nickNamesList.length > 1 &&
                              nickNamesList.map((name, index) => {
                                return (
                                  <option
                                    className="font-weight-light"
                                    value={name}
                                    key={index}
                                  >
                                    {name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      }
                      <div className="mt-2">
                        <select
                          key={`${name}`}
                          className="custom-select w-100"
                          onChange={handleChange}
                          name="paymentOptn"
                          required
                        >
                          <option value="direct">Direct pay</option>
                          <option value="bid">Place a Bid</option>;
                        </select>
                      </div>
                      {
                        paymentOptn === "direct" && (
                          <div className="mt-4">
                            <button
                              className="btn btn-primary mt-2 w-100"
                              onClick={handleReserve}
                            >
                              Reserve With {currency}{" "}
                              {bidParameter.directPayAmount ?? ""}
                            </button>
                          </div>
                        )
                        // (

                        // )
                      }
                      {/* <div className="mt-5  d-flex justify-content-between">
                        <button className="btn btn-primary w-50 font-weight-normal">
                          Pay with X amount
                        </button>
                        <button className="btn btn-primary font-weight-normal">
                          Place a bid
                        </button>
                      </div> */}
                    </form>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mt-4 mt-lg-0">
                    {paymentOptn === "bid" && (
                      <div className="row m-0 p-0 ">
                        <h4> Top Bidders List</h4>
                        <div className="col-12 bid-list-container">
                          <table className="table table-striped">
                            <thead
                              className="position-sticky top-0"
                              style={{ position: "sticky", zIndex: 1 }}
                            >
                              <tr>
                                <th scope="col">Sno</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col">Bid Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {bidderList.map((bidder, index) => {
                                return (
                                  <tr key={`${bidder.phoneNumber}-${index}`}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{bidder.phoneNumber}</td>
                                    <td>
                                      {bidder.bidAmount}{" "}
                                      {bidder.countryCode === "+977"
                                        ? "NPR"
                                        : "INR"}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12 p-0 mt-2">
                          <label className="font-weight-light">
                            Enter bid amount
                          </label>
                          <div className="form-group">
                            <input
                              className="form-range"
                              type="range"
                              placeholder="Enter Bid Amount"
                              min={bidParameter.minBidAmount}
                              max={parseFloat(bidParameter.minBidAmount) * 10}
                              step={bidParameter.bidIncrementAmount}
                              required
                              name="bidAmount"
                              onChange={handleChange}
                            />
                            {bidAmount}
                            {/* <div className="d-flex w-100 justify-content-between p-2">
                              <div className="text-danger font-weight-bold small">
                                Minimum bid amount: {bidParameter.minBidAmount}{" "}
                                {currency}
                              </div>
                              <div className="text-danger font-weight-bold small">
                                Maximum bid amount: {bidParameter.maxBidAmount}{" "}
                                {currency}
                              </div>
                            </div> */}
                            <div>
                              For confirmation {bidParameter.bidEntryFee}{" "}
                              {currency}
                              will be charged as a bid verification.
                            </div>
                            <div className="mt-2">
                              <button
                                className="btn btn-primary mt-2 w-100"
                                onClick={handleBid}
                              >
                                Bid
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-silhouette"></div>
      </div>
    </div>
  );
};
